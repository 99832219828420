import "./index.scss"
import React, { useState } from "react"
import { useForm } from "react-form"
import Button from "../../common/button/button"
import TextField from "../../common/form/text-field/text-field"
import styles from "../../common/button/button.module.scss"
import "react-notifications/lib/notifications.css"
import { createKeywords, updateKeywords } from "../../../services/keyword"
import { MdError, MdCheck } from "react-icons/md"
const AddKeywords = ({ addNode, closeHandler, updateKeyword }) => {
  const [submitAttempted, setSubmitAttempted] = useState()
  const [node, setNode] = useState(addNode)
  const [keywords, setKeywords] = useState([])

  const [showMessage, setShowMessage] = useState(false)
  const [error, setError] = useState()
  const [success, setSuccess] = useState()

  const AddNode = event => {
    const { name, value } = event.target
    setKeywords({ ...keywords, [name]: value })
  }

  const onSaveClick = () => {
    setSubmitAttempted(true)
  }

  const formInstance = useForm({
    debugForm: false,
    onSubmit: values => {
      const id = addNode.id
      if (addNode.id === "root") {
        const ParentKeywords = {
          seedId: "keyword_" + values.term,
          term: values.term,
          parentPath: "",
          children: "",
        }
        createKeywords(ParentKeywords).then(
          result => {
            if (result.error) {
              setShowMessage(true)
              setError(result.error)
            } else {
              setShowMessage(true)
              setSuccess("Keyword created successfully")
              setKeywords(ParentKeywords)
              updateKeyword()
              if (closeHandler) {
                closeHandler()
              }
            }
          },
          error => {
            setShowMessage(true)
            setError("We found duplicate of this keyword")
          }
        )
      } else if (addNode.child !== "") {
        const addChild = {
          children: addNode.child + "," + values.term,
        }

        let path = ""
        let originalParent = ""
        if (addNode.parentPath) {
          const index = addNode.parentPath.indexOf("=>")
          const oldParentPath = addNode.parentPath
          const parentPath = oldParentPath.substr(index + 3, oldParentPath.length)

          originalParent = addNode.module + " " + "=>" + " " + values.term + "," + parentPath
        }
        else {
          path = addNode.module + " => " + values.term + "," + addNode.module
        } 

        const children = node.children

        const keywordObj = {
          term: values.term,
          seedId: "keyword_" + values.term,
          parentPath: originalParent !== "" ? originalParent : path,
          children: "",
        }

        createKeywords(keywordObj).then(
          result => {
            if (result.error) {
              setShowMessage(true)
              setError(result.error)
            } else {
              setShowMessage(true)
              setSuccess("Keyword created successfully")
              setKeywords(keywordObj)
              updateKeywords(id, addChild).then(keywords => {
                setKeywords(keywords)
                updateKeyword()
                if (closeHandler) {
                  closeHandler()
                }
              })
            }
          },
          error => {
            setShowMessage(true)
            setError("We found duplicate of this keyword")
          }
        )
      } else {
        const addChild = {
          children: values.term,
        }

        const index = addNode.parentPath.indexOf("=>")
        const test = addNode.parentPath
        const parentPath = test.substr(index + 3, test.length)

        let path = ""
        let originalParent = ""
        if (addNode.parentPath === parentPath) {
          path =
            addNode.module +
            " " +
            "=>" +
            " " +
            values.term +
            "," +
            addNode.module
        } else {
          originalParent =
            addNode.module + " " + "=>" + " " + values.term + "," + parentPath
        }

        const children = node.children

        const keywordObj = {
          term: values.term,
          seedId: "keyword_" + values.term,
          parentPath: originalParent !== "" ? originalParent : path,
          children: "",
        }

        createKeywords(keywordObj).then(
          result => {
            if (result.error) {
              setShowMessage(true)
              setError(result.error)
            } else {
              setShowMessage(true)
              setSuccess("Keyword created successfully")
              setKeywords(keywordObj)
              updateKeywords(id, addChild).then(keywords => {
                setKeywords(keywords)
                updateKeyword()
                if (closeHandler) {
                  closeHandler()
                }
              })
            }
          },
          error => {
            setShowMessage(true)
            setError("We found duplicate of this keyword")
          }
        )
      }
    },
    validate: (values, instance) => {
      setShowMessage(false)
      setSubmitAttempted(false)
    },
  })
  const getAddError = () => {
    if (showMessage) {
      if (error) {
        return (
          <div className="keyError">
            <MdError /> {error}
          </div>
        )
      } else {
        return (
         
             <div className="keySuccess">
            <MdCheck /> {success}
          </div>
        )
      }
    }
  }

  const { Form, meta, pushFieldValue } = formInstance

  return (
    <div>
      <Form>
        <div className="addModalShow">
          <div className="modal-body">
            {getAddError()}
            <TextField
              label="Add Keyword"
              field="term"
              validate={value => {
                return !value ? "Please Enter a Valid Term!" : false
              }}
              mandatory
            />

            <div className={styles.action}>
              <div className={styles.buttonWrap}>
                <br />
                <Button
                  id="save-company-data"
                  label="Save"
                  type="submit"
                  btnStyle="primary"
                  btnSize="small"
                  fullWidth
                  prependIcon="MdSave"
                  onClick={onSaveClick}
                />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}
export default AddKeywords
