import React, { useState } from "react"
import KeywordManager from "../../components/keyword-manager/keyword-manager"
import ReactTooltip from "react-tooltip"

const Keywords = ({ location }) => {
  console.log("current location: ", location)
  const [item, setItem] = useState({})

  return (
    <>
      <KeywordManager item={item} location={location} isCreate={true} />
      <ReactTooltip
        place="bottom"
        type="info"
        effect="solid"
        globalEventOff="click"
      />
    </>
  )
}

export default Keywords
